// @flow strict-local

import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import { createHttpLink, ApolloClient } from '@apollo/client';

import { GRAPHQL_URI } from './config';
import { resetVariables } from './graphql/reactive-variables';

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Forwarded-Proto': 'https',
    // authorization: token ? `Bearer ${token}` : '',
  },
}));

const clientCache = new InMemoryCache();

// $FlowFixMe
const client: ApolloClient<$FlowFixMe> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: clientCache,
});

client.onResetStore(() => {
  resetVariables();
});

export default client;

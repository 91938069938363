// @flow strict
import { makeVar } from '@apollo/client';

const customer = {
  points: 0,
  fullName: null,
};

export const customerVar: $FlowFixMe = makeVar(customer);

export const updateCustomerVar = (params: {
  points: number,
  fullName: string,
}) => {
  const old = customerVar();
  customerVar({
    ...old,
    ...params,
  });
};

// eslint-disable-next-line import/prefer-default-export
export const resetVariables = () => {};
